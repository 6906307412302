<template>
  <div>
    <clinical-data></clinical-data>
  </div>
</template>

<script>
import ClinicalData from "../components/ClinicalData.vue";

export default {
  name: "Dashboard",
  components: {
    ClinicalData
  }
};
</script>
