<template>
  <div>
    <h1 class="text-center my-3 greencolor">NEONATAL UNIT</h1>

    <v-row justify="center">
      <v-card class="pa-8 rounded-xl" elevation="12">
        <v-card color="green">
          <h2 class="white--text ml-3 text-center">
            {{ totalPatients }}<span class="d-block">Patients</span>
          </h2>
        </v-card>
        <v-img src="uploads/ntbaby.jpg" contain class="my-7 rounded-xl"></v-img>

        <v-row justify="space-around">
          <v-btn
            rounded
            :to="{ name: 'patients' }"
            color="green darken-2"
            class="white--text"
          >
            <v-icon>mdi-account-multiple</v-icon>
            Patients
          </v-btn>
          <v-btn
            rounded
            :to="{ name: 'newPatient' }"
            color="green darken-2"
            class="white--text"
          >
            <v-icon>mdi-plus-box</v-icon>
            New Patient
          </v-btn>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import { apiClient } from "@/services";
// import {apiClient, uuid} from "@/services"

export default {
  name: "ClinicalData",
  data() {
    return {
      totalPatients: 0
    };
  },
  created() {
    apiClient
      .get("/?u=2&count=1")
      .then(res => {
        if (res.data.total) {
          this.totalPatients = res.data.total;
        }
      })
      .catch(err => {
        console.log(err.message);
      });
  }
};
</script>

<style>
.greencolor {
  color: #0b580b;
}
</style>
